* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color:rgba(0, 0, 0, 0.884);
  color:white;
  max-width: 100vw;
}

a {
  color: inherit;
}


